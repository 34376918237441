import React from 'react';
import IntlMessages from 'helpers/IntlMessages';
/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'pt';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
  { id: 'pt', name: 'Português - Brasil', direction: 'ltr' },
];

export const searchPath = '/app/pages/search';
export const servicePath = 'https://api.coloredstrategies.com';

/*
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = false;
export const defaultColor = 'light.blue';
export const defaultDirection = 'ltr';
export const isDarkSwitchActive = false;
export const themeColorStorageKey = '__theme_color';
export const themeRadiusStorageKey = '__theme_radius';
export const isDemo = false;

export const DEFAULT_PAGE_SIZE = 10;

export const INITIAL_PAGE = 1;

export const REFUEL_STATUS = {
  DISABLED: 'data.refuelDisabled',
  ENABLED: 'data.refuelEnabled',
};

export const STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DELETED: 'deleted',
};

export const BALANCE_DEPOSIT_TYPE = {
  CREDIT_TRANSFER: 'CREDIT_TRANSFER',
  CREDIT_REFUND: 'CREDIT_REFUND',
  CREDIT_ADDITION: 'CREDIT_ADDITION',
  TRANSACTION_CANCELLATION: 'TRANSACTION_CANCELLATION',
};

export const STATUS_TRANSACTIONS = {
  APROVED: 'data.approved',
  PENDING: 'data.pending',
  CANCELED: 'data.canceled',
};

export const FRAUD_ATTEMPS_TYPES = {
  PENDING: 'pending',
  CONFIRMED: 'confirmed',
  FALSELYFLAGGED: 'falselyFlagged',
};

export const STATUS_FRAUD_ATTEMPS = {
  [FRAUD_ATTEMPS_TYPES.PENDING]: 'data.pending',
  [FRAUD_ATTEMPS_TYPES.CONFIRMED]: 'data.confirmed',
  [FRAUD_ATTEMPS_TYPES.FALSELYFLAGGED]: 'data.falsely-flagged',
};

export const REFUEL_FRAUD_SUSPITION_REASON = {
  DEVICE_DETECTED_DURING_REFUELING: 'data.device-detected-during-refueling',
};

export const AFFILIATED_TYPES = {
  AFFILIATED_CHAIN: 'pages.affiliatedChain',
  ANYWHERE: 'pages.anywhere',
};

export const STATUS_PURCHASE = {
  PENDING: 'data.pending',
  PAID: 'data.paid',
  CANCELLED: 'data.cancelled',
};

export const PAYMENT_TYPE = {
  PIX: 'pages.payment-type-pix',
  BILL: 'pages.payment-type-bill',
  TRANSFER: 'pages.payment-type-transfer',
};

export const STATUS_TYPES_PURCHASE = [
  {
    label: <IntlMessages id={STATUS_PURCHASE.PENDING} />,
    value: 'pending',
    key: 1,
  },
  {
    label: <IntlMessages id={STATUS_PURCHASE.PAID} />,
    value: 'paid',
    key: 2,
  },
  {
    label: <IntlMessages id={STATUS_PURCHASE.CANCELLED} />,
    value: 'cancelled',
    key: 3,
  },
];

export const defaultPageSizes = [5, 10, 25, 50, 100];

export const billDateOptions = [
  // { label: <IntlMessages id="pages.weekly" />, value: () => '07', key: 1 },
  { label: <IntlMessages id="pages.fortnightly" />, value: () => '15', key: 2 },
  {
    label: <IntlMessages id="pages.monthly" />,
    value: (month, year) => new Date(year, month, 0).getDate(),
    key: 3,
  },
];

export const STATUS_TYPES = [
  { label: <IntlMessages id={STATUS.ACTIVE} />, value: true, key: 1 },
  { label: <IntlMessages id={STATUS.INACTIVE} />, value: false, key: 2 },
  { label: <IntlMessages id={STATUS.DELETED} />, value: 'isDeleted', key: 3 },
];

export const STATUS_CODE = {
  [STATUS.ACTIVE]: STATUS_TYPES[0],
  [STATUS.INACTIVE]: STATUS_TYPES[1],
  [STATUS.DELETED]: STATUS_TYPES[2],
};

export const STATUS_REFUEL_FILTER = [
  { label: <IntlMessages id={REFUEL_STATUS.ENABLED} />, value: true, key: 1 },
  { label: <IntlMessages id={REFUEL_STATUS.DISABLED} />, value: false, key: 2 },
];

export const STATUS_REFUEL_CODE = {
  [REFUEL_STATUS.ENABLED]: STATUS_REFUEL_FILTER[0],
  [REFUEL_STATUS.DISABLED]: STATUS_REFUEL_FILTER[1],
};

export const TRANSACTIONS_STATUS_TYPES = [
  {
    label: <IntlMessages id={STATUS_TRANSACTIONS.APROVED} />,
    value: 'active',
    key: 1,
  },
  {
    label: <IntlMessages id={STATUS_TRANSACTIONS.CANCELED} />,
    value: 'inactive',
    key: 2,
  },
];

export const TRANSACTION_TYPE = [
  {
    label: <IntlMessages id={AFFILIATED_TYPES.AFFILIATED_CHAIN} />,
    value: 'affiliatedChain',
    key: 1,
  },
  {
    label: <IntlMessages id={AFFILIATED_TYPES.ANYWHERE} />,
    value: 'anywhere',
    key: 2,
  },
];

export const GAS_STATION_CATEGORY = [
  {
    label: <IntlMessages id={AFFILIATED_TYPES.AFFILIATED_CHAIN} />,
    value: 'affiliatedChain',
    key: 1,
  },
  {
    label: <IntlMessages id={AFFILIATED_TYPES.ANYWHERE} />,
    value: 'anywhere',
    key: 2,
  },
];

export const STATUS_PAYMENT = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  DONE: 'done',
  CANCELED: 'canceled',
};

export const BILLING_STATUS_TYPES = [
  {
    label: 'Pendente',
    value: 'pending',
    key: 1,
  },
  {
    label: 'Processando',
    value: 'processing',
    key: 2,
  },
  {
    label: 'Pago',
    value: 'done',
    key: 3,
  },
  {
    label: 'Cancelado',
    value: 'canceled',
    key: 4,
  },
];

export const FUEL_TYPES = [
  { label: <IntlMessages id="fuel.ethanol" />, value: 'ethanol', key: 1 },
  {
    label: <IntlMessages id="fuel.premiumEthanol" />,
    value: 'premiumEthanol',
    key: 2,
  },
  { label: <IntlMessages id="fuel.diesel" />, value: 'diesel', key: 3 },
  {
    label: <IntlMessages id="fuel.dieselS500" />,
    value: 'dieselS500',
    key: 4,
  },
  {
    label: <IntlMessages id="fuel.carrierNaturalGas" />,
    value: 'carrierNaturalGas',
    key: 5,
  },
  { label: <IntlMessages id="fuel.gas" />, value: 'gas', key: 6 },
  {
    label: <IntlMessages id="fuel.premiumGas" />,
    value: 'premiumGas',
    key: 7,
  },
  { label: <IntlMessages id="fuel.arla" />, value: 'arla', key: 8 },
];

export const VEHICLE_FUEL_TYPES = [
  { label: <IntlMessages id="fuel.gas" />, value: 'gas', key: 0 },
  { label: <IntlMessages id="fuel.ethanol" />, value: 'ethanol', key: 1 },
  { label: <IntlMessages id="fuel.flex" />, value: 'flex', key: 2 },
  { label: <IntlMessages id="fuel.diesel" />, value: 'diesel', key: 3 },
  { label: <IntlMessages id="fuel.dieselS500" />, key: 4 },
  {
    label: <IntlMessages id="fuel.carrierNaturalGas" />,
    value: 'carrierNaturalGas',
    key: 5,
  },
  {
    label: <IntlMessages id="fuel.carrierNaturalGasFlex" />,
    value: 'carrierNaturalGasFlex',
    key: 6,
  }
];

export const userCategories = [
  { label: <IntlMessages id="pages.driver" />, value: 'employee', key: 1 },
  { label: <IntlMessages id="pages.recipient" />, value: 'contractor', key: 2 },
];

export const DEBOUNCE_TIME = 1000;

export const TRANSACTION_PASS_MAX_LENGTH = 4;

export const MODULE_MODAL_DATA = {
  key: '',
  name: '',
  description: '',
  status: '',
  price: '',
};

export const VEHICLE_MODAL_DATA = {
  _organization: '',
  licensePlate: '',
  make: '',
  model: '',
  year: '',
  color: '',
  tank: '',
  efficiency: '',
  odometer: '',
  fuel: '',
};

export const ORGANIZATION_MODAL_DATA = {
  federalTaxNumber: '',
  name: '',
  tradeName: '',
  email: '',
  address: '',
  number: '',
  neighborhood: '',
  postalCode: '',
  city: '',
  state: '',
  website: '',
};

export const GAS_STATION_MODAL_DATA = {
  federalTaxNumber: '',
  name: '',
  tradeName: '',
  email: '',
  address: '',
  number: '',
  neighborhood: '',
  postalCode: '',
  city: '',
  state: '',
  website: '',
  latitude: '',
  longitude: '',
  chargedFee: '',
  fuels: {
    ethanol: {
      availability: false,
      price: 0,
    },
    premiumEthanol: {
      availability: false,
      price: 0,
    },
    gas: {
      availability: false,
      price: 0,
    },
    premiumGas: {
      availability: false,
      price: 0,
    },
    diesel: {
      availability: false,
      price: 0,
    },
    dieselS500: {
      availability: false,
      price: 0,
    },
    arla: {
      availability: false,
      price: 0,
    },
    carrierNaturalGas: {
      availability: false,
      price: 0,
    },
  },
  operatingHours: {
    sunday: {
      opens: '',
      closes: '',
    },
    monday: {
      opens: '',
      closes: '',
    },
    tuesday: {
      opens: '',
      closes: '',
    },
    wednesday: {
      opens: '',
      closes: '',
    },
    thursday: {
      opens: '',
      closes: '',
    },
    friday: {
      opens: '',
      closes: '',
    },
    saturday: {
      opens: '',
      closes: '',
    },
  },
};

export const PERMISSIONS_DOMAINS = [
  {
    label: <IntlMessages id="domain.admin" />,
    value: 'com.litroz.portalAdmin',
    key: 1,
  },
  {
    label: <IntlMessages id="domain.portal" />,
    value: 'com.litroz.portal',
    key: 2,
  },
  {
    label: <IntlMessages id="domain.gas-stations" />,
    value: 'com.litroz.gasStationPortal',
    key: 3,
  },
];

export const ACCESS_TYPES = [
  {
    label: <IntlMessages id="permission.list" />,
    value: 'list',
    key: 1,
  },
  {
    label: <IntlMessages id="permission.add" />,
    value: 'add',
    key: 2,
  },
  {
    label: <IntlMessages id="permission.remove" />,
    value: 'remove',
    key: 3,
  },
];

export const PERMISSION_MODAL_DATA = {
  domain: '',
  name: '',
  access: '',
  path: '',
  description: '',
};

export const POSTAL_CODE_LENGTH = 8;

export const RESET_SORT_COUNT = 3;

export const DISCOUNT_TYPES = {
  ethanol: [
    {
      label: 'pages.value',
      value: 'value',
      id: 'ethanol-value',
      defaultChecked: true,
    },
    {
      label: 'pages.percent',
      value: 'percent',
      id: 'ethanol-percent',
      defaultChecked: false,
    },
    {
      label: 'pages.price',
      value: 'price',
      id: 'ethanol-percent',
      defaultChecked: false,
    },
  ],
  premiumEthanol: [
    {
      label: 'pages.value',
      value: 'value',
      id: 'premiumEthanol-value',
      defaultChecked: true,
    },
    {
      label: 'pages.percent',
      value: 'percent',
      id: 'premiumEthanol-percent',
      defaultChecked: false,
    },
    {
      label: 'pages.price',
      value: 'price',
      id: 'premiumEthanol-percent',
      defaultChecked: false,
    },
  ],
  gas: [
    {
      label: 'pages.value',
      value: 'value',
      id: 'gas-value',
      defaultChecked: true,
    },
    {
      label: 'pages.percent',
      value: 'percent',
      id: 'gas-percent',
      defaultChecked: false,
    },
    {
      label: 'pages.price',
      value: 'price',
      id: 'gas-percent',
      defaultChecked: false,
    },
  ],
  premiumGas: [
    {
      label: 'pages.value',
      value: 'value',
      id: 'premiumGas-value',
      defaultChecked: true,
    },
    {
      label: 'pages.percent',
      value: 'percent',
      id: 'premiumGas-percent',
      defaultChecked: false,
    },
    {
      label: 'pages.price',
      value: 'price',
      id: 'premiumGas-percent',
      defaultChecked: false,
    },
  ],
  diesel: [
    {
      label: 'pages.value',
      value: 'value',
      id: 'diesel-value',
      defaultChecked: true,
    },
    {
      label: 'pages.percent',
      value: 'percent',
      id: 'diesel-percent',
      defaultChecked: false,
    },
    {
      label: 'pages.price',
      value: 'price',
      id: 'diesel-percent',
      defaultChecked: false,
    },
  ],
  dieselS500: [
    {
      label: 'pages.value',
      value: 'value',
      id: 'dieselS500-value',
      defaultChecked: true,
    },
    {
      label: 'pages.percent',
      value: 'percent',
      id: 'dieselS500-percent',
      defaultChecked: false,
    },
    {
      label: 'pages.price',
      value: 'price',
      id: 'dieselS500-percent',
      defaultChecked: false,
    },
  ],
  arla: [
    {
      label: 'pages.value',
      value: 'value',
      id: 'arla-value',
      defaultChecked: true,
    },
    {
      label: 'pages.percent',
      value: 'percent',
      id: 'arla-percent',
      defaultChecked: false,
    },
    {
      label: 'pages.price',
      value: 'price',
      id: 'arla-percent',
      defaultChecked: false,
    },
  ],
  carrierNaturalGas: [
    {
      label: 'pages.value',
      value: 'value',
      id: 'carrierNaturalGas-value',
      defaultChecked: true,
    },
    {
      label: 'pages.percent',
      value: 'percent',
      id: 'carrierNaturalGas-percent',
      defaultChecked: false,
    },
    {
      label: 'pages.price',
      value: 'price',
      id: 'carrierNaturalGas-percent',
      defaultChecked: false,
    },
  ],
};

export const PAYMENT_PERIODS = [
  { label: <IntlMessages id="period.7days" />, value: 7, key: 0 },
  { label: <IntlMessages id="period.15days" />, value: 15, key: 1 },
  { label: <IntlMessages id="period.30days" />, value: 30, key: 2 },
  { label: <IntlMessages id="period.24hours" />, value: 1, key: 3 },
];

export const GAS_STATION_WEEK_PAYMENT = 7;

export const GAS_STATION_PRE_PAID = 1;

export const PAYMENT_WEEK_DAY_REFERENCE = [
  { label: <IntlMessages id="pages.monday" />, value: 0, key: 0 },
  { label: <IntlMessages id="pages.tuesday" />, value: 1, key: 1 },
  { label: <IntlMessages id="pages.wednesday" />, value: 2, key: 2 },
  { label: <IntlMessages id="pages.thursday" />, value: 3, key: 3 },
  { label: <IntlMessages id="pages.friday" />, value: 4, key: 4 },
];

export const BILLING_MODEL = [
  {
    label: <IntlMessages id="model.prepaid" />,
    value: 'pre-paid',
    key: 0,
  },
  { label: <IntlMessages id="model.postpaid" />, value: 'post-paid', key: 1 },
];

export const BILLING_PERIODS = [
  { label: <IntlMessages id="period.7days" />, value: 7, key: 0 },
  { label: <IntlMessages id="period.15days" />, value: 15, key: 1 },
  { label: <IntlMessages id="period.30days" />, value: 30, key: 2 },
];

export const BILLING_WEEK_DAY_REFERENCE = [
  { label: <IntlMessages id="pages.monday" />, value: 0, key: 0 },
  { label: <IntlMessages id="pages.tuesday" />, value: 1, key: 1 },
  { label: <IntlMessages id="pages.wednesday" />, value: 2, key: 2 },
  { label: <IntlMessages id="pages.thursday" />, value: 3, key: 3 },
  { label: <IntlMessages id="pages.friday" />, value: 4, key: 4 },
  { label: <IntlMessages id="pages.saturday" />, value: 5, key: 5 },
  { label: <IntlMessages id="pages.sunday" />, value: 6, key: 6 },
];

export const ANYWHERE_CHARGED_FEE_TYPE = [
  { label: <IntlMessages id="pages.value" />, value: 'value', key: 0 },
  { label: <IntlMessages id="pages.percent" />, value: 'percent', key: 1 },
];

export const BILLING_METHODS = {
  PRE_PAID: 'pre-paid',
  POST_PAID: 'post-paid',
};

export const PERIODS = {
  1: 'weekly',
  2: 'fortnightly',
  3: 'monthly',
};

export const PAYMENT_METHOD_FREQUENCY_NAMES = {
  WEEKLY: 'weekly',
  FORTNIGHTLY: 'fortnightly',
  MONTHLY: 'monthly',
};

export const BILLS_PERIODS = [
  { label: <IntlMessages id="period.3months" />, value: 3, key: 0 },
  { label: <IntlMessages id="period.6months" />, value: 6, key: 1 },
  { label: <IntlMessages id="period.12months" />, value: 12, key: 2 },
  { label: <IntlMessages id="period.24months" />, value: 24, key: 3 },
];

export const DISCOUNT = 'discount';

export const INTEGRATION_PROVIDERS = [{ label: 'LBC', value: 'lbc', key: 0 }];

export const LBC_PROVIDER = 'lbc';

export const PASSWORD = 'password';

export const CREDIT_PROVIDERS = [
  {
    label: <IntlMessages id="credit-provider.litroz" />,
    value: 'litroz',
    key: 0,
  },
  { label: <IntlMessages id="credit-provider.arbi" />, value: 'arbi', key: 1 },
];

export const BALANCE_SHARING_TYPE = {
  EMPLOYEE: 'employee',
  VEHICLE: 'vehicle',
};

export const PAYMENT_AUTHORIZATION_TYPE = [
  {
    label: <IntlMessages id="pages.block" />,
    value: 'blackList',
    key: 0,
  },
  {
    label: <IntlMessages id="pages.liberate" />,
    value: 'whiteList',
    key: 1,
  },
];

export const PAYMENT_AUTHORIZATION_INPUT_TYPE = [
  {
    label: 'input.cnpj',
    id: 'cnpj',
    defaultChecked: true,
    value: 'cnpj',
  },
  {
    label: 'input.pix-key',
    id: 'pixKey',
    defaultChecked: false,
    value: 'pixKey',
  },
];
